import { useState } from 'react';

// Definindo o tipo genérico para o hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  // Estado que guarda o valor
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Tenta obter o valor do localStorage
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error("Erro ao ler do localStorage", error);
      return initialValue;
    }
  });

  // Função para salvar no localStorage
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error("Erro ao salvar no localStorage", error);
    }
  };

  return [storedValue, setValue] as const;
}
